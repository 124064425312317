exports.components = {
  "component---src-components-slideshow-tsx": () => import("./../../../src/components/Slideshow.tsx" /* webpackChunkName: "component---src-components-slideshow-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-housing-tsx": () => import("./../../../src/pages/housing.tsx" /* webpackChunkName: "component---src-pages-housing-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-tsx": () => import("./../../../src/pages/info.tsx" /* webpackChunkName: "component---src-pages-info-tsx" */),
  "component---src-pages-project-contentful-project-internal-name-tsx": () => import("./../../../src/pages/project/{contentfulProject.internalName}.tsx" /* webpackChunkName: "component---src-pages-project-contentful-project-internal-name-tsx" */),
  "component---src-pages-public-tsx": () => import("./../../../src/pages/public.tsx" /* webpackChunkName: "component---src-pages-public-tsx" */),
  "component---src-pages-urban-tsx": () => import("./../../../src/pages/urban.tsx" /* webpackChunkName: "component---src-pages-urban-tsx" */)
}

